import { reactive } from "vue"

export const sidenav = reactive({ expanded: false })

export const handleSideNav = (e = "toggle") => {
    if (e === "toggle") {
        sidenav.expanded = !sidenav.expanded
    } else {
        sidenav.expanded = e
    }
}
