<template>
    <Layout>
        <div class="title-div">
            <div class="title-main">Terms & Conditions</div>
            <span></span>
        </div>
        <div class="lang-select-div">
            <select v-model="lang.language" @change="handleSelectLanguage">
                <option
                    v-for="opt in languageOptions"
                    :key="opt.value"
                    :value="opt.value"
                >
                    {{ opt.text }}
                </option>
            </select>
        </div>

        <div v-if="isLocalLoading" class="loader-container local">
            <font-awesome-icon icon="spinner"></font-awesome-icon>
        </div>
        <div v-else-if="data && data.length" class="tnc-div">
            <ul class="tnc-nav">
                <li
                    :class="{
                        'tnc-nav-link': true,
                        active: navLink?.id === currentActiveNavId,
                    }"
                    v-for="navLink in TNCNavLinks"
                    :key="navLink?.id"
                    @click="selectNavLink(navLink.id)"
                >
                    <span>{{ navLink.title }}</span>
                    <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                </li>
            </ul>
            <div class="tnc-editor-div">
                <CKEditor.component
                    :editor="editor"
                    :config="editorConfig"
                    v-model="editorInput"
                    @input="onEditorInput"
                ></CKEditor.component>
            </div>
        </div>
        <div v-else class="no-data-container">
            <font-awesome-icon icon="circle-question"></font-awesome-icon>
            <div>No Data</div>
        </div>
        <div class="cta-div">
            <button
                class="cta-button"
                @click="handleSave"
                :disabled="isLocalLoading"
            >
                Save
            </button>
        </div>
    </Layout>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-vue"
import Layout from "../layout/Layout.vue"
import { onMounted, watch } from "vue"
import { toast } from "../../composable/useToast"
import { getAPI, postAPI } from "../../composable/useFetch"
import { setLoader } from "../../composable/useLayoutLoader"
import { handleSideNav } from "../../composable/useSidenav"

const data = ref([])
const TNCNavLinks = ref([])
const isLocalLoading = ref(false)

const languageOptions = [
    { text: "English", value: "en" },
    { text: "Malay", value: "ms" },
    { text: "Chinese", value: "cn" },
]

const lang = reactive({
    language: languageOptions[0].value,
})

const editorInput = ref()
const editor = ref(ClassicEditor)
const editorConfig = ref({
    width: "auto",
})

const onEditorInput = (e) => {
    editorInput.value = e
}

const currentActiveNavId = ref(null)

const selectNavLink = (key) => {
    // Remind to save
    if (
        editorInput.value !==
        data.value.find((d) => d.id === currentActiveNavId.value)?.content
    ) {
        toast("Please save before continue", "error")
        return
    }

    currentActiveNavId.value = key
}

const handleSave = async (e) => {
    e.preventDefault()
    const original = data.value.find((d) => d.id === currentActiveNavId.value)
    const payloadToUpdate = {
        title: original.title,
        content: editorInput.value,
    }
    const saveResponse = await postAPI(
        `/api/terms/${currentActiveNavId.value}`,
        payloadToUpdate
    )
    if (saveResponse.error) {
        return
    }
    toast("Successfully saved")
    data.value = data.value.map((d) =>
        d.id !== currentActiveNavId.value ? d : { ...d, ...saveResponse }
    )
}

const handleSelectLanguage = () => {
    fetchData(true)
}

const fetchData = async (disabledGlobalLoader) => {
    if (!disabledGlobalLoader) {
        setLoader(true)
    } else {
        isLocalLoading.value = true
    }
    const response = await getAPI(`/api/terms?language=${lang.language}`)
    data.value = response
    TNCNavLinks.value = response.map(({ id, title }) => ({ id, title }))
    currentActiveNavId.value = TNCNavLinks.value[0]?.id
    if (!disabledGlobalLoader) {
        setLoader(false)
    } else {
        isLocalLoading.value = false
    }
}

watch(currentActiveNavId, (n) => {
    editorInput.value = data.value.find((d) => d.id === n)?.content
})

onMounted(async () => {
    fetchData()
    handleSideNav(false)
})
</script>

<style scoped>
.cta-div {
    border-top: 1px solid #d8d8d8;
    margin-top: var(--content-gap);
    padding: var(--content-gap);
    display: flex;
    justify-content: center;
}
.cta-button {
    border: none;
    border-radius: 4px;
    background-color: var(--color-kt-yellow);
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 16px 24px;
    min-width: 150px;
}
</style>
