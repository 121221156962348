<style scoped>
.side-nav {
    position: fixed;
    top: var(--top-nav-height);
    min-height: calc(100vh - var(--top-nav-height));
    width: var(--sidenav-width);
    background-color: #eee;
    padding: 20px 0;
    flex-shrink: 0;
    transition: 300ms;
}
ul {
    text-align: left;
}
a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px 20px;
    color: #0b3459;
    font-size: 17px;
    text-decoration: none;
    text-transform: none;
}
a span {
    display: flex;
    max-width: 160px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
a:hover {
    background-color: #ddd;
}
a:active,
a.active {
    background-color: #ffb400;
}

@media screen and (min-width: 600px) {
}
</style>

<template>
    <div
        id="sidenav-wrapper"
        :class="['sidenav-wrapper', sidenav.expanded ? 'show' : '']"
        @click="closeSideNav"
    >
        <div class="side-nav">
            <ul>
                <li
                    v-for="sideNavLink in sideNavLinks"
                    :key="sideNavLink.label"
                >
                    <router-link
                        :to="sideNavLink.href"
                        :class="{
                            active: route.path.indexOf(sideNavLink.href) > -1,
                        }"
                    >
                        <span>{{ sideNavLink.label }}</span>
                        <font-awesome-icon
                            icon="chevron-right"
                        ></font-awesome-icon>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted } from "vue"
import { useRoute } from "vue-router"
import { handleSideNav, sidenav } from "../../composable/useSidenav"

const route = useRoute()

const sideNavLinks = [
    { label: "Banner", href: "/banner" },
    { label: "News", href: "/news" },
    { label: "Term & Condition", href: "/tnc" },
]

const closeSideNav = (e) => {
    if (e.target.id === "sidenav-wrapper") {
        handleSideNav(false)
    }
}
const handleSidenavClose = (e) => {
    switch (e.key) {
        case "Escape":
            handleSideNav(false)
            break

        default:
            break
    }
}

onMounted(() => {
    document.addEventListener("keydown", handleSidenavClose)
})

onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleSidenavClose)
})
</script>
