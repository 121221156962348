<style scoped>
.top-nav {
    z-index: 3;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--top-nav-height);
    padding: 0 20px;
    background-color: var(--color-kt-blue);
    color: var(--color-kt-yellow);
}
.top-nav-actions {
    display: flex;
    align-items: center;
}
.nav-logo {
    height: 32px;
}
.nav-logo img {
    height: 100%;
    width: auto;
}
.invisible {
    font-size: 18px;
    border: none;
    color: inherit;
    background-color: transparent;
}
</style>

<template>
    <div class="top-nav">
        <button @click="handleSideNav()" class="invisible sidenav-toggler">
            <font-awesome-icon icon="bars"></font-awesome-icon>
        </button>
        <div class="nav-logo">
            <img :src="companyLogo" />
        </div>
        <div v-if="!props.isPublic" class="top-nav-actions">
            <button @click="logout" class="invisible">
                <font-awesome-icon
                    icon="arrow-right-from-bracket"
                ></font-awesome-icon>
            </button>
        </div>
        <span v-else></span>
    </div>
</template>

<script setup>
import { defineProps } from "vue"
import { handleSideNav } from "../../composable/useSidenav"
import { handleLogout } from "../../composable/useAuth"
import { useRouter } from "vue-router"
const companyLogo = require("../../assets/dark-logo-only.png")
const router = useRouter()

const props = defineProps({
    isPublic: {
        type: Boolean,
    },
})

const logout = async () => {
    const r = await handleLogout()
    if (r) {
        router.push("/login")
    }
}
</script>
