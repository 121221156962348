<template>
    <Layout>
        <div class="title-div">
            <div class="title-main">News</div>
            <button class="button-outline button-yellow" @click="handleAddNew">
                Add New
            </button>
        </div>
        <div v-if="data && data.length" class="table-div">
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Images</th>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(d, i) in data" :key="d.id">
                            <td>{{ i + 1 }}</td>
                            <td>
                                <div v-if="!d.img" class="table-image-empty">
                                    <font-awesome-icon
                                        icon="image"
                                    ></font-awesome-icon>
                                </div>
                                <img
                                    v-else
                                    class="table-image"
                                    :src="`/storage/app/news/${d.img}`"
                                />
                            </td>
                            <td>{{ d.title }}</td>
                            <td>{{ d.updated_at?.slice(0, 10) || "-" }}</td>
                            <td>
                                <div class="action-in-table">
                                    <ToggleSwitch
                                        v-model:checked="d.activeRef"
                                        :disabled="
                                            !d.img
                                                ? 'Please upload cover image first'
                                                : null
                                        "
                                        @change="handleToggle(d.id)"
                                    ></ToggleSwitch>
                                    <button
                                        class="square-button red"
                                        @click="handleDeleteModalOpen(d.id)"
                                        :disabled="data.length <= 1"
                                    >
                                        <font-awesome-icon
                                            icon="trash-can"
                                        ></font-awesome-icon>
                                    </button>
                                    <button
                                        class="square-button blue"
                                        @click="handleEdit(d.id)"
                                    >
                                        <font-awesome-icon
                                            icon="pen"
                                        ></font-awesome-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination-container">
                <div class="pagination-detail">
                    {{ page * LIMIT + 1 }} -
                    {{ Math.min(total, (page + 1) * LIMIT) }} of
                    {{ total }}
                </div>
                <div class="pagination-button-container">
                    <button
                        class="pagination-button"
                        :disabled="page === 0"
                        @click="pagination.goStart"
                    >
                        {{ "<<" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="page === 0"
                        @click="pagination.goPrevious"
                    >
                        {{ "<" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="
                            page ===
                            Math.max(Math.floor((total - 1) / LIMIT), 0)
                        "
                        @click="pagination.goNext"
                    >
                        {{ ">" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="
                            page ===
                            Math.max(Math.floor((total - 1) / LIMIT), 0)
                        "
                        @click="pagination.goEnd"
                    >
                        {{ ">>" }}
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="no-data-container">
            <font-awesome-icon icon="circle-question"></font-awesome-icon>
            <div>No Data</div>
        </div>
    </Layout>
    <ModalLayout
        :isOpen="isDeleteModalOpen"
        @onClose="handleDeleteModalClose"
        @onRoot="setModalRef"
    >
        <DeleteConfirmationModal
            @onSubmit="handleDelete"
            @onClose="handleDeleteModalClose"
            :id="deletingId"
        ></DeleteConfirmationModal>
    </ModalLayout>
</template>

<script setup>
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { getAPI, postAPI } from "../../composable/useFetch"
import { setLoader } from "../../composable/useLayoutLoader"
import { handleSideNav } from "../../composable/useSidenav"
import { toast } from "../../composable/useToast"
import Layout from "../layout/Layout.vue"
import ModalLayout from "../layout/ModalLayout.vue"
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal.vue"
import ToggleSwitch from "../shared/UI/ToggleSwitch.vue"

const page = ref(0)
const total = ref()
const data = ref([])
const deletingId = ref(null)
const isDeleteModalOpen = ref(false)

const router = useRouter()

let LIMIT = 20 // per_page

const modalRef = ref()

const setModalRef = (r) => {
    modalRef.value = r
}

const pagination = {
    goStart: () => {
        page.value = 0
        fetchData()
    },
    goPrevious: () => {
        page.value = Math.max(page.value - 1, 0)
        fetchData()
    },
    goNext: () => {
        page.value = Math.min(page.value + 1, Math.floor(total.value / LIMIT))
        fetchData()
    },
    goEnd: () => {
        page.value = Math.floor(total.value / LIMIT)
        fetchData()
    },
}

const handleAddNew = async () => {
    router.push(`/news/create`)
}

const handleToggle = async (id) => {
    const target = data.value.find((d) => d.id === id)
    if (!target || !target.img) {
        return
    }
    const toggleResponse = await postAPI(`/api/news/${id}/toggle`)
    if ("active" in toggleResponse) {
        toast(`Successfully turn ${toggleResponse.active ? "on" : "off"}`)
    }
}

const handleEdit = (id) => {
    router.push(`/news/${id}`)
}

const handleDeleteModalOpen = (id) => {
    deletingId.value = id
    isDeleteModalOpen.value = true
}

const handleDeleteModalClose = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isDeleteModalOpen.value = false
            },
            { once: true }
        )
    } else {
        isDeleteModalOpen.value = false
    }
}

const handleDelete = async (newsId, callback) => {
    const deleteResponse = await postAPI(`/api/news/${newsId}/delete`)
    if (deleteResponse.message) {
        toast("News has been deleted")
        data.value = data.value.filter((d) => d.id !== newsId)
    }
    callback()
}

const fetchData = async () => {
    setLoader(true)
    const response = await getAPI(`/api/news?page=${page.value}`)
    data.value = response.data.map((d) => ({
        ...d,
        activeRef: ref(!!d.active),
    }))
    total.value = response.total
    LIMIT = response.per_page || LIMIT
    setLoader(false)
}

onMounted(() => {
    fetchData()
    handleSideNav(false)
})
</script>

<style scoped>
.table-div {
    margin-top: var(--content-gap);
}
table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
table thead {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}
table thead tr {
    border-bottom: 1px solid var(--color-kt-blue);
}
table thead tr th,
table tbody tr td {
    padding: 16px;
    white-space: nowrap;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
}
table tbody tr:nth-child(2n-1) {
    background-color: #eee;
}
.title-main {
    font-size: 30px;
    color: var(--color-kt-blue);
}

.table-image {
    height: 80px;
    width: 160px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
}
.table-image-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 160px;
    background-color: #ccc;
    border-radius: 4px;
    color: white;
    margin: 0 auto;
    font-size: 28px;
}
.action-in-table {
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-in-table > * + * {
    margin-left: 12px;
}
.square-button {
    border-radius: 4px;
    height: 40px;
    width: 40px;
    border: none;
    color: white;
}
.square-button.blue {
    background-color: var(--color-kt-blue);
}
.square-button.red {
    background-color: var(--color-kt-red);
}
.square-button:disabled {
    background-color: #aaa;
}
.button-outline {
    font-size: 17px;
    font-weight: bold;
    min-width: 150px;
    padding: 10px 16px;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    background-color: transparent;
}
.button-yellow {
    color: var(--color-kt-yellow);
    border-color: var(--color-kt-yellow);
}
</style>
