<template>
    <label :for="r" :class="{ disabled: props.disabled }">
        <input
            :id="r"
            type="checkbox"
            :checked="props.checked"
            @input="onInput"
            @change="toogle"
            hidden
        />
        <div :class="{ 'toggle-switch-base': true, on: props.checked }"></div>
    </label>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue"
import { toast } from "../../../composable/useToast"

const props = defineProps({
    checked: Boolean,
    disabled: String,
})

const r = ref(Math.floor(Math.random() * 99))

const emit = defineEmits(["update:checked"])

const onInput = (e) => {
    if (props.disabled) {
        toast(props.disabled || "Error", "error")
        return
    }
    emit("update:checked", e.target.checked)
}
</script>

<style scoped>
label {
    cursor: pointer;
    margin: 0px calc(20px * 0.3);
}
label.disabled {
    cursor: not-allowed;
}
label.disabled > * {
    background-color: #ddd !important;
}
.toggle-switch-base {
    position: relative;
    display: flex;
    align-items: center;
    height: 14px;
    border-radius: 7px;
    width: 32px;
    background-color: #ddd;
}
.toggle-switch-base.on {
    background-color: var(--color-kt-light-green);
}

.toggle-switch-base::after {
    content: "";
    position: absolute;
    left: 0;
    right: unset;
    transform: translateX(-30%);
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #ddd;
    box-shadow: 0 0 3px #999;
    transition: 300ms;
}
.toggle-switch-base.on::after {
    position: absolute;
    left: unset;
    right: 0;
    transform: translateX(30%);
    background-color: var(--color-kt-green);
}
label.disabled .toggle-switch-base.on::after {
    background-color: #ddd;
}
</style>
