import { reactive } from "vue"

export const toastList = reactive({ t: [] })

export const toast = (msg, type = "success", timeout = 5000) => {
    const id = Math.floor(Math.random() * 1000)
    const timer = setTimeout(() => {
        handleTimeout(id, removeToastById)
    }, timeout)
    toastList.t.push({ id, msg, type, timer })
}

const removeToastById = (id) => {
    toastList.t = toastList.t.filter((t) => t.id !== id)
}

const handleTimeout = (id, cb) => {
    const t = document.querySelector(`#toast-${id}`)
    if (t) {
        t.classList.add("closing")
        t.addEventListener("animationend", () => {
            cb(id)
        })
    } else {
        cb(id)
    }
}
