<template>
    <!-- <a href="#" >Go back</a> -->
    <button class="link-button" @click="goBack">Back</button>
</template>

<script setup>
import { useRouter } from "vue-router"

const router = useRouter()
const goBack = () => {
    router.go(-1)
}
</script>

<style></style>
