<template>
    <LayoutPublic>
        <span></span>
        <form @submit.prevent="login">
            <div class="logo-div">
                <img class="main-logo" :src="companyLogoWithNumber" />
            </div>
            <label>Email</label>
            <input type="email" name="email" required />
            <label>Password</label>
            <input type="password" name="password" required />
            <button class="button yellow">Login</button>
        </form>
        <div class="footer">
            Copyrights © {{ year }} All Rights Reserved by KT Express Logistics
            Sdn Bhd (1146177-A)
        </div>
    </LayoutPublic>
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { handleLogin } from "../../composable/useAuth"
import LayoutPublic from "../layout/LayoutPublic.vue"

const companyLogoWithNumber = require("../../assets/dark-com-number.png")
const router = useRouter()
const year = ref(new Date().getFullYear())

const login = async (e) => {
    const fd = new FormData(e.target)
    const r = await handleLogin(fd)
    if (r) {
        router.push("/scanner")
    }
    // const response = await dodoo.post("/api/auth/login", fd);
    // console.log({response})
}
</script>

<style scoped>
* {
    text-align: left;
}
.logo-div {
    margin-bottom: 40px;
}
.logo-div img {
    width: 100%;
}
form {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
label {
    color: var(--color-kt-yellow);
    display: block;
    margin-bottom: 12px;
}
input {
    width: 100%;
    margin-bottom: var(--form-input-gap);
}
button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    margin: 16px auto 0;
}
.footer {
    font-size: 17px;
    color: #979797;
}
</style>
