<template>
    <Layout>
        <div class="title-div">
            <div class="title-main">Banner</div>
            <button class="button-outline button-yellow" @click="handleAddNew">
                Add New
            </button>
        </div>
        <div v-if="banners && banners.length" class="table-div">
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Images</th>
                            <th>Url</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(banner, i) in banners" :key="banner.id">
                            <td>{{ i + 1 }}</td>
                            <td>
                                <div class="table-img-wrapper">
                                    <div
                                        v-if="!banner.img_desktop"
                                        class="table-image-empty"
                                    >
                                        <font-awesome-icon
                                            icon="image"
                                        ></font-awesome-icon>
                                    </div>
                                    <img
                                        v-else
                                        class="table-image"
                                        :src="`/storage/app/banners/${banner.img_desktop}`"
                                    />

                                    <div
                                        v-if="!banner.img_mobile"
                                        class="table-image-portrait-empty"
                                    >
                                        <font-awesome-icon
                                            icon="image"
                                        ></font-awesome-icon>
                                    </div>
                                    <img
                                        v-else
                                        class="table-image-portrait"
                                        :src="`/storage/app/banners/${banner.img_mobile}`"
                                    />
                                </div>
                            </td>
                            <td>
                                {{
                                    banner.url && banner.url != 0
                                        ? banner.url
                                        : "-"
                                }}
                            </td>
                            <td>
                                <div class="action-in-table">
                                    <ToggleSwitch
                                        v-model:checked="banner.activeRef"
                                        :disabled="
                                            !banner.img_desktop ||
                                            !banner.img_mobile
                                                ? 'Please upload the image(s) first'
                                                : null
                                        "
                                        @change="handleToggle(banner.id)"
                                    ></ToggleSwitch>
                                    <button
                                        class="square-button red"
                                        @click="
                                            handleDeleteModalOpen(banner.id)
                                        "
                                        :disabled="banners.length <= 1"
                                    >
                                        <font-awesome-icon
                                            icon="trash-can"
                                        ></font-awesome-icon>
                                    </button>
                                    <button
                                        class="square-button blue"
                                        @click="handleEdit(banner.id)"
                                    >
                                        <font-awesome-icon
                                            icon="pen"
                                        ></font-awesome-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pagination-container">
                <div class="pagination-detail">
                    {{ page * LIMIT + 1 }} -
                    {{ Math.min(total, (page + 1) * LIMIT) }} of
                    {{ total }}
                </div>
                <div class="pagination-button-container">
                    <button
                        class="pagination-button"
                        :disabled="page === 0"
                        @click="pagination.goStart"
                    >
                        {{ "<<" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="page === 0"
                        @click="pagination.goPrevious"
                    >
                        {{ "<" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="
                            page ===
                            Math.max(Math.floor((total - 1) / LIMIT), 0)
                        "
                        @click="pagination.goNext"
                    >
                        {{ ">" }}
                    </button>
                    <button
                        class="pagination-button"
                        :disabled="
                            page ===
                            Math.max(Math.floor((total - 1) / LIMIT), 0)
                        "
                        @click="pagination.goEnd"
                    >
                        {{ ">>" }}
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="no-data-container">
            <font-awesome-icon icon="circle-question"></font-awesome-icon>
            <div>No Data</div>
        </div>
    </Layout>
    <ModalLayout
        :isOpen="isDeleteModalOpen"
        @onClose="handleDeleteModalClose"
        @onRoot="setModalRef"
    >
        <DeleteConfirmationModal
            @onSubmit="handleDelete"
            @onClose="handleDeleteModalClose"
            :id="deletingId"
        ></DeleteConfirmationModal>
    </ModalLayout>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { onMounted } from "vue"
import { useRouter } from "vue-router"
import { getAPI, postAPI } from "../../composable/useFetch"
import { setLoader } from "../../composable/useLayoutLoader"
import { handleSideNav } from "../../composable/useSidenav"
import { toast } from "../../composable/useToast"
import Layout from "../layout/Layout.vue"
import ModalLayout from "../layout/ModalLayout.vue"
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal.vue"
import ToggleSwitch from "../shared/UI/ToggleSwitch.vue"

const page = ref(0)
const total = ref()
const banners = ref([])
const deletingId = ref(null)
const isDeleteModalOpen = ref(false)

const modalRef = ref()

const router = useRouter()

let LIMIT = 20 // per_page

const setModalRef = (r) => {
    modalRef.value = r
}

const pagination = {
    goStart: () => {
        page.value = 0
        fetchData()
    },
    goPrevious: () => {
        page.value = Math.max(page.value - 1, 0)
        fetchData()
    },
    goNext: () => {
        page.value = Math.min(page.value + 1, Math.floor(total.value / LIMIT))
        fetchData()
    },
    goEnd: () => {
        page.value = Math.floor(total.value / LIMIT)
        fetchData()
    },
}

const handleAddNew = async () => {
    const new_banner = await postAPI("/api/banner")
    banners.value = banners.value.push(new_banner)
    router.push(`/banner/${new_banner.id}`)
}

const handleToggle = async (bannerId) => {
    const target = banners.value.find((banner) => banner.id === bannerId)

    if (!target || !target.img_desktop || !target.img_mobile) {
        return
    }
    const toggleResponse = await postAPI(`/api/banner/${bannerId}/toggle`)
    if ("active" in toggleResponse) {
        toast(`Successfully turn ${toggleResponse.active ? "on" : "off"}`)
    }
}

const handleEdit = (bannerId) => {
    router.push(`/banner/${bannerId}`)
}

const handleDeleteModalOpen = (id) => {
    deletingId.value = id
    isDeleteModalOpen.value = true
}

const handleDeleteModalClose = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isDeleteModalOpen.value = false
            },
            { once: true }
        )
    } else {
        isDeleteModalOpen.value = false
    }
}

const handleDelete = async (bannerId, callback) => {
    const deleteResponse = await postAPI(`/api/banner/${bannerId}/delete`)
    if (deleteResponse.message) {
        toast("Banner has been deleted")
        banners.value = banners.value.filter((banner) => banner.id !== bannerId)
        total.value = banners.value.length
    }
    callback()
}

const fetchData = async () => {
    setLoader(true)
    // const response = await getAPI(`/api/banner?page=${page.value}`);
    // banners.value = response.data.map(d => ({...d, activeRef: ref(!!d.active)}));
    // total.value = response.total;
    // LIMIT = response.per_page || LIMIT;
    const response = await getAPI(`/api/banner?page=${page.value}`)
    banners.value = response.map((d) => ({ ...d, activeRef: ref(!!d.active) }))
    total.value = response.length
    LIMIT = response.per_page || LIMIT
    setLoader(false)
}

onMounted(async () => {
    fetchData()
    handleSideNav(false)
})
</script>
