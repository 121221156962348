import { reactive, ref } from "vue"
import { postAPI } from "./useFetch"
import { toast } from "./useToast"

export const lastCheck = ref(0)

export const auth = reactive({ user: null })

export const checkUser = async () => {
    const response = await postAPI("/api/auth/current_user")
    if (response && response.id) {
        lastCheck.value = Date.now()
        auth.user = response
        return auth.user
    }
    return
}

export const handleLogin = async (data) => {
    const response = await postAPI("/api/auth/login", data)
    if (response.access_token) {
        lastCheck.value = Date.now()
        localStorage.setItem("access_token", response.access_token)
        await checkUser()
        return true
    }
    return
}
export const handeRegister = async (data) => {
    const response = await postAPI("/api/auth/register", data)
    if (response.id) {
        return response
    }
    return
}
export const handleLogout = async () => {
    const response = await postAPI("/api/auth/logout")
    localStorage.removeItem("access_token")
    auth.user = null
    if (response.message) {
        toast("Successfully logged out")
        return true
    }
    return
}
