import { toast } from "./useToast"

export const getAPI = (url) => {
    return _call("GET", url)
}

export const postAPI = (url, body, options = {}) => {
    let headers = {}
    if (!(body instanceof FormData)) {
        body = JSON.stringify(body)
        headers = {
            "Content-type": "application/json",
        }
    }

    return _call("POST", url, { ...options, headers, body })
}

const _call = async (method, url, options = {}) => {
    const { headers, ...o } = options
    // Populate option
    const opts = {
        method,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Accept: "application/json",
            ...headers,
        },
        ...o,
    }

    const resp = await fetch(url, opts)
    if (resp.status !== 200) {
        if (resp.status >= 400 && resp.status < 500) {
            // Log user out
            toast("Not authorized", "error")
            return
        }
        if (resp.status) {
            return toast("Something went wrong", "error")
        }
    }

    if (resp.headers.get("content-type")?.toLowerCase()?.indexOf("json") > -1) {
        const r = await resp.json()
        if (r.error) {
            toast(r.error, "error")
        }
        return r
    }
}
