<style>
.wrapper {
    display: flex;
    width: 100%;
}
.main-public {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    min-height: 100vh;
    width: 100%;
    padding: var(--content-gap);
    background-color: var(--color-kt-blue);
}
.title-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>

<template>
    <div class="wrapper">
        <div class="main-public">
            <slot></slot>
        </div>
    </div>
</template>

<script setup></script>
