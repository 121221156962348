// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router

import { createRouter, createWebHistory } from "vue-router"
import Login from "../components/views/Login.vue"
import BannerView from "../components/views/BannerView.vue"
import BannerNew from "../components/views/BannerNew.vue"
import News from "../components/views/News.vue"
import NewsCreate from "../components/views/NewsCreate.vue"
import NewsEdit from "../components/views/NewsEdit.vue"
import TNCView from "../components/views/TNCView.vue"
// import { auth, checkUser, lastCheck } from "@/composable/useAuth";
// import {  checkUser, useAuth } from "../composable/store";

export const routeInfos = [
    // Public routes
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next()
            } else {
                next({
                    path: "/",
                    replace: true,
                })
            }
        },
    },
    // Redirection routes
    {
        path: "/",
        name: "Home",
        redirect: "/banner",
    },
    // Private routes
    {
        path: "/banner",
        component: BannerView,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/banner/:id",
        component: BannerNew,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/news",
        component: News,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/news/create",
        component: NewsCreate,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/news/:id",
        component: NewsEdit,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/tnc",
        component: TNCView,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    { path: "/:catchAll(.*)", redirect: "/" },
]

const router = createRouter({
    history: createWebHistory("/"),
    routes: routeInfos,
})

// router.beforeEach(async (to) => {
//     if(!localStorage["access_token"]){
//         if(to.meta.requiresAuth){
//             return {
//                 path: "/login",
//                 replace: true
//                 // save the location we were at to come back later
//                 //   query: { redirect: to.fullPath },
//             }
//         }
//         return;
//     }
//     console.log(Date.now())
//     console.log(lastCheck.value)
//     if(Date.now() - lastCheck.value > 5 * 60 * 1000 ) {
//       await checkUser();
//     }

//     if(to.meta.requiresAuth && !auth.user){
//         return {
//             path: "/login",
//             replace: true
//             // save the location we were at to come back later
//             //   query: { redirect: to.fullPath },
//         }
//     }

//     if(to.meta.unAuthedOnly && auth.user) {
//         return {
//             path: "/banner",
//             replace: true
//         }
//     }
// });

export default router
