<style scoped>
.full {
    width: 100%;
}
.centered {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
</style>
<template>
    <slot>
        <div class="centered">This page is not available</div>
    </slot>
</template>

<script setup></script>
