<template>
    <Layout>
        <div class="title-div">
            <BackButton></BackButton>
            <span></span>
        </div>
        <div class="banner-div">
            <div>
                <div class="titl">Desktop</div>
                <div class="landscape">
                    <div
                        class="upload-div"
                        id="img_desktop"
                        @click="handleFileInputTrigger"
                    >
                        <div class="spacer"></div>
                        <div class="desc default">
                            <div>Upload Image</div>
                            <div>2000px * 1000px</div>
                        </div>
                        <div class="desc h">
                            <div>Drop to Upload</div>
                        </div>
                    </div>
                    <div
                        v-if="data && data.img_desktop && img_desktop_path"
                        class="preview-div"
                    >
                        <img class="preview-image" :src="img_desktop_path" />
                        <button
                            class="link-button"
                            @click="handleDeletePreview('img_desktop')"
                        >
                            Delete
                        </button>
                    </div>
                    <div v-else></div>
                    <input
                        type="file"
                        name="landscape_banner"
                        id="input-img_desktop"
                        multiple="0"
                        @change="handleFileInput"
                        hidden
                    />
                </div>
            </div>
            <div class="v-line"></div>
            <div>
                <div class="titl">Mobile</div>
                <div class="portrait">
                    <div
                        class="upload-div"
                        id="img_mobile"
                        @click="handleFileInputTrigger"
                    >
                        <div class="spacer"></div>
                        <div class="desc default">
                            <div>Upload Image</div>
                            <div>900px * 1600px</div>
                        </div>
                        <div class="desc h">
                            <div>Drop to Upload</div>
                        </div>
                    </div>

                    <div
                        v-if="data && data.img_mobile && img_mobile_path"
                        class="preview-div"
                    >
                        <img class="preview-image" :src="img_mobile_path" />
                        <button
                            class="link-button"
                            @click="handleDeletePreview('img_mobile')"
                        >
                            Delete
                        </button>
                    </div>

                    <div v-else></div>
                    <input
                        type="file"
                        name="portrait_banner"
                        id="input-img_mobile"
                        multiple="0"
                        @change="handleFileInput"
                        hidden
                    />
                </div>
            </div>
        </div>
        <form @submit.prevent="handleSave">
            <div class="url-div">
                <label>Link URL</label>
                <input
                    type="url"
                    v-model="data.url"
                    placeholder="https://www.ktexpress.com.my"
                />
            </div>
            <div class="cta-div">
                <button
                    class="cta-button"
                    @click="handleSave"
                    :disabled="!!isSaving"
                >
                    Save
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from "vue"
import { useRoute } from "vue-router"
import { getAPI, postAPI } from "../../composable/useFetch"
import { setLoader } from "../../composable/useLayoutLoader"
import { handleSideNav } from "../../composable/useSidenav"
import { toast } from "../../composable/useToast"
import Layout from "../layout/Layout.vue"
import BackButton from "../shared/UI/BackButton.vue"

let bannerId

const route = useRoute()
const data = reactive({
    img_desktop: "",
    img_mobile: "",
    url: "",
})
const isSaving = ref(false)

const img_desktop_path = computed(() => {
    return data?.img_desktop ? `/storage/app/banners/${data.img_desktop}` : ""
})
const img_mobile_path = computed(() => {
    return data?.img_mobile ? `/storage/app/banners/${data.img_mobile}` : ""
})

const handleFileInputTrigger = (e) => {
    const id = e.currentTarget.id
    if (!id) {
        return
    }
    document.querySelector(`#input-${id}`).click()
}

const handleFileInput = (e) => {
    const fileToProcess = e.target.files[0]
    uploadFile(fileToProcess, e.currentTarget.id.split("-")[1])
}

const handleDeletePreview = async (id) => {
    if (!data[id]) {
        return
    }
    const deleteResponse = await postAPI(
        `/api/banner/${route.params.id}/delete_${id}`
    )
    if (deleteResponse.message) {
        data[id] = null
        toast(`Image [${id}] has been removed`)
    }
}

function highlight(e) {
    e.preventDefault()
    e.stopPropagation()
    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (!a.classList.contains("highlight")) {
            a.addEventListener("drop", handleDrop, { once: true })
        }
        a.classList.add("highlight")
    })
}

function unhighlight(e) {
    e.preventDefault()
    e.stopPropagation()

    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (a.classList.contains("highlight")) {
            a.removeEventListener("drop", handleDrop)
        }
        a.classList.remove("highlight")
    })
}

function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    unhighlight(e)
    let dt = e.dataTransfer
    let files = dt.files
    const fileToUpload = files[0]
    uploadFile(fileToUpload, e.currentTarget.id)
}

async function uploadFile(file, id) {
    let uploadResponse
    const fd = new FormData()
    fd.append(id, file)
    const allowed_keys = ["img_desktop", "img_mobile"]
    if (allowed_keys.includes(id)) {
        uploadResponse = await postAPI(
            `/api/banner/${route.params.id}/upload_${id}`,
            fd
        )
    }
    if (id in uploadResponse) {
        toast(`Image [${id}] saved`)
        data[id] = uploadResponse[id]
    }
}

const handleSave = async (e) => {
    e.preventDefault()
    if (isSaving.value) {
        return
    }

    if (!data.img_desktop || !data.img_mobile) {
        toast("Please upload image", "error")
        return
    }

    isSaving.value = true
    const saveResponse = await postAPI(`/api/banner/${route.params.id}`, {
        url: data.url?.trim() || "",
    })
    if (saveResponse.id) {
        toast("Successfully saved")
    }
    isSaving.value = false
}

const fetchData = async () => {
    setLoader(true)
    const response = await getAPI(`/api/banner/${route.params.id}`)
    if (response.url && response.url != 0) {
        data.id = response.id
        data.img_desktop = response.img_desktop
        data.img_mobile = response.img_mobile
        data.url = response.url
        data.active = response.active
    } else {
        response.url = ""
        data.id = response.id
        data.img_desktop = response.img_desktop
        data.img_mobile = response.img_mobile
        data.url = response.url
        data.active = response.active
    }
    setLoader(false)
}

onMounted(() => {
    fetchData()
    bannerId = route.params.id

    const body = document.querySelector("body")

    body.addEventListener("dragenter", highlight, false)
    body.addEventListener("dragover", highlight, false)
    body.addEventListener("dragleave", unhighlight, false)
    body.addEventListener("drop", unhighlight, false)

    handleSideNav(false)
})

onBeforeUnmount(async () => {
    const body = document.querySelector("body")

    body.removeEventListener("dragenter", highlight, false)
    body.removeEventListener("dragover", highlight, false)
    body.removeEventListener("dragleave", unhighlight, false)
    body.removeEventListener("drop", unhighlight, false)

    if ((!data.img_mobile || !data.img_desktop) && data.active) {
        const res = await postAPI(`/api/banner/${bannerId}/toggle`)
        if ("active" in res) {
            toast("Banner has been inactivated due to missing image", "error")
        }
    }
})
</script>

<style scoped>
.url-div {
    text-align: left;
    margin-top: var(--content-gap);
}
.url-div label {
    display: block;
    margin-bottom: 12px;
}
.url-div input {
    width: 400px;
    max-width: 100%;
}
label {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
}
input[type="text"],
input[type="url"] {
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    padding: 10px 16px;
    font-size: 17px;
}
.banner-div {
    margin-top: var(--content-gap);
    display: grid;
    grid-template-columns: var(--banner-grid-template-column);
    gap: var(--content-gap);
    text-align: left;
}
.titl {
    color: var(--color-kt-blue);
    font-size: 30px;
    margin-bottom: 12px;
}
.landscape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.portrait {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.upload-div {
    position: relative;
    border-radius: 4px;
    border: 2px dashed #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.landscape .upload-div {
    width: 100%;
}
.landscape .upload-div > .spacer {
    width: 100%;
    padding-bottom: 50%;
}
.portrait .upload-div {
    width: 50%;
}
.portrait .upload-div > .spacer {
    width: 100%;
    padding-bottom: calc(16 / 9 * 100%);
}

.landscape .preview-div {
    width: 100%;
}
.portrait .preview-div {
    width: calc(50% - var(--content-gap));
}

.preview-div > .card {
    overflow: hidden;
    background: #eee;
    border: 1px solid #979797;
    border-radius: 4px;
}
.landscape .preview-div .card {
    width: 33%;
    padding-bottom: calc(33% * 0.5);
}
.portrait .preview-div .card {
    width: 50%;
    padding-bottom: calc(16 / 9 * 50%);
}
/* .desc {
        pointer-events: none;
        user-select: none;
        color: #979797;
        font-size: 17px;
        position: absolute;
    }
    .desc.h {
        display: none;
    }  */
.banner-div a {
    display: block;
    margin-top: 12px;
}
.preview-image {
    overflow: hidden;
    border: 1px solid #979797;
    border-radius: 4px;
    object-fit: cover;
}
.landscape .preview-image {
    width: 35%;
    aspect-ratio: 2;
}
.portrait .preview-image {
    width: 50%;
    aspect-ratio: 9 / 16;
}
</style>
