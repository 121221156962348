<template>
    <Layout>
        <div class="title-div">
            <BackButton></BackButton>
            <span></span>
        </div>
        <div class="news-div">
            <div>
                <div class="titl">Cover Image</div>
                <div class="landscape">
                    <div
                        class="upload-div"
                        id="img"
                        @click="handleFileInputTrigger"
                    >
                        <div class="spacer"></div>
                        <div class="desc default">
                            <div>Upload Image</div>
                            <div>960px * 540px</div>
                        </div>
                        <div class="desc h">
                            <div>Drop to Upload</div>
                        </div>
                    </div>
                    <div v-if="data && data.img" class="preview-div">
                        <img
                            class="preview-image"
                            v-bind:src="`/storage/app/news/${data.img}`"
                        />
                        <button class="link-button" @click="handleDeleteImage">
                            Delete
                        </button>
                    </div>
                    <div v-else class="input-warning">
                        Please upload a cover image
                    </div>
                    <input
                        type="file"
                        name="landscape_banner"
                        id="input-img"
                        multiple="0"
                        @change="handleFileInput"
                        hidden
                    />
                </div>
            </div>
            <div class="v-line"></div>
            <div>
                <div class="news-content-div">
                    <label> Language </label>
                    <select v-model="data.language">
                        <option value="en">English</option>
                        <option value="ms">Malay</option>
                        <option value="cn">Chinese</option>
                    </select>
                    <label> Date </label>
                    <input type="date" v-model="data.date" />
                    <label> News Title </label>
                    <input
                        type="text"
                        class="input-full"
                        v-model="data.title"
                    />
                    <label> Permalink </label>
                    <input
                        type="text"
                        class="input-full"
                        readonly
                        disabled
                        v-model="data.permalink"
                    />
                    <label> Summary </label>
                    <textarea rows="4" v-model="data.summary"></textarea>
                    <div class="editor-div">
                        <CKEditor.component
                            :editor="editor"
                            :config="editorConfig"
                            v-model="data.article"
                            @input="onEditorInput"
                        ></CKEditor.component>
                    </div>
                </div>
            </div>
        </div>
        <div class="cta-div">
            <button class="cta-button" @click="handleSave">Save</button>
        </div>
    </Layout>
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-vue"
import Layout from "../layout/Layout.vue"
import BackButton from "../shared/UI/BackButton.vue"
import { useRoute } from "vue-router"
import { toast } from "../../composable/useToast"
import { getAPI, postAPI } from "../../composable/useFetch"
import { getTodayDate } from "../../composable/useHelper"
import { setLoader } from "../../composable/useLayoutLoader"
import { handleSideNav } from "../../composable/useSidenav"

let newsId
const route = useRoute()
const data = reactive({
    title: "",
    summary: "",
    article: "",
    permalink: "",
    language: "en",
    date: null,
    img: null,
    active: null,
})

const previews = ref({
    landscape: null,
})

const editorInput = ref()
const editor = ref(ClassicEditor)
const editorConfig = ref({
    ckfinder: {
        uploadUrl: `/api/news/upload?token=${localStorage.getItem(
            "access_token"
        )}`,
    },
    height: 200,
})

const onEditorInput = (e) => {
    editorInput.value = e
}

const handleFileInputTrigger = (e) => {
    const id = e.currentTarget.id
    if (!id) {
        return
    }
    document.querySelector(`#input-${id}`).click()
}

const handleFileInput = (e) => {
    const fileToProcess = e.target.files[0]
    // previewFile(fileToProcess, e.currentTarget.id.split("-")[1]);
    uploadFile(fileToProcess, e.currentTarget.id.split("-")[1])
}

const handleDeleteImage = async () => {
    const deleteImgResponse = await postAPI(
        `/api/news/${route.params.id}/delete_img`
    )
    if (deleteImgResponse.message) {
        data.img = null
        toast("Image has been deleted")
    }
}

function highlight(e) {
    e.preventDefault()
    e.stopPropagation()
    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (!a.classList.contains("highlight")) {
            a.addEventListener("drop", handleDrop, { once: true })
        }
        a.classList.add("highlight")
    })
    // console.log(e.target);
    // console.log(e);
    // target.forEach(t=>t.classList.add('highlight'));
    // e.target.classList.add('highlight');
}

function unhighlight(e) {
    e.preventDefault()
    e.stopPropagation()

    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (a.classList.contains("highlight")) {
            a.removeEventListener("drop", handleDrop)
        }
        a.classList.remove("highlight")
    })
    // target.forEach(t=>t.classList.remove('highlight'));
    // e.target.classList.remove('highlight');
}

function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    unhighlight(e)
    let dt = e.dataTransfer
    let files = dt.files
    const fileToUpload = files[0]
    previewFile(fileToUpload, e.currentTarget.id)
    uploadFile(fileToUpload, e.currentTarget.id)
}

function previewFile(file, id) {
    if (!file) {
        return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
        previews.value[id] = reader.result
    }
}

async function uploadFile(file, id) {
    let uploadResponse
    const fd = new FormData()
    fd.append(id, file)
    const allowed_keys = ["img"]
    if (allowed_keys.includes(id)) {
        uploadResponse = await postAPI(
            `/api/news/${route.params.id}/upload_img`,
            fd
        )
        if (uploadResponse.img) {
            data.img = uploadResponse.img
            toast("Image saved")
        }
    }
}

const handleSave = async (e) => {
    e.preventDefault()

    if (!data.img) {
        toast("Please upload a cover image", "error")
        return
    }
    const fd = new FormData()
    for (let i in data) {
        if (data[i]) {
            fd.append(i, data[i])
        }
    }

    const saveResponse = await postAPI(`/api/news/${route.params.id}`, data, {
        headers: { "Content-Type": "application/json" },
    })

    if (saveResponse.id) {
        data.title = saveResponse.title
        data.summary = saveResponse.summary
        data.article = saveResponse.article
        data.date = getTodayDate(saveResponse.updated_at)
        data.img = saveResponse.img
        data.permalink = saveResponse.permalink
        toast("News saved")
    }
}

const fetchData = async () => {
    setLoader(true)

    const response = await getAPI(`/api/news/${route.params.id}`)
    if (response.error) {
        toast(response.error, "error")
        return
    }
    data.title = response.title
    data.summary = response.summary
    data.article = response.article
    data.date = getTodayDate(response.updated_at)
    data.img = response.img
    data.language = response.language
    data.permalink = response.permalink
    data.active = response.active
    setLoader(false)
}

onMounted(() => {
    fetchData()
    newsId = route.params.id

    const body = document.querySelector("body")

    body.addEventListener("dragenter", highlight, false)
    body.addEventListener("dragover", highlight, false)
    body.addEventListener("dragleave", unhighlight, false)
    body.addEventListener("drop", unhighlight, false)

    handleSideNav(false)
})

onBeforeUnmount(async () => {
    const body = document.querySelector("body")

    body.removeEventListener("dragenter", highlight, false)
    body.removeEventListener("dragover", highlight, false)
    body.removeEventListener("dragleave", unhighlight, false)
    body.removeEventListener("drop", unhighlight, false)

    if (!data.img && data.active) {
        const res = await postAPI(`/api/news/${newsId}/toggle`)
        if ("active" in res) {
            toast(
                "News has been inactivated due to missing cover image",
                "error"
            )
        }
    }
})
</script>

<style scoped>
.news-content-div select,
.news-content-div input,
.news-content-div textarea {
    margin-bottom: var(--form-input-gap);
}
label {
    display: block;
    margin-bottom: 8px;
}
.news-div {
    margin-top: var(--content-gap);
    display: grid;
    grid-template-columns: var(--news-grid-template-column);
    gap: var(--content-gap);
    text-align: left;
}
.news-div > * {
    min-width: 0;
}
.titl {
    color: var(--color-kt-blue);
    font-size: 30px;
    margin-bottom: 12px;
}
.landscape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.portrait {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.upload-div {
    position: relative;
    border-radius: 4px;
    border: 2px dashed #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.landscape .upload-div {
    width: 100%;
}
.landscape .upload-div > .spacer {
    width: 100%;
    padding-bottom: calc(540 / 960 * 100%);
}

.landscape .preview-div {
    width: 100%;
}
.portrait .preview-div {
    width: calc(50% - var(--content-gap));
}

.preview-div > .card {
    overflow: hidden;
    background: #eee;
    border: 1px solid #979797;
    border-radius: 4px;
}
.landscape .preview-div .card {
    width: 33%;
    padding-bottom: calc(33% * 540 / 960);
}

.banner-div a {
    display: block;
    margin-top: 12px;
}
.preview-image {
    overflow: hidden;
    border: 1px solid #979797;
    border-radius: 4px;
    object-fit: cover;
}
.landscape .preview-image {
    width: 35%;
    aspect-ratio: 960 / 540;
}
</style>
