<template>
    <Teleport to="#modal">
        <div v-if="props.isOpen" class="modal-wrapper" ref="modalRef">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue"
const props = defineProps({
    isOpen: Boolean,
})

const emit = defineEmits(["onClose", "onRoot"])

const modalRef = ref()

const handleClose = () => {
    emit("onClose")
}

const handleModalKey = (e) => {
    switch (e.key) {
        case "Escape":
            handleClose()
            break

        default:
            break
    }
}

onMounted(() => {
    document.addEventListener("keydown", handleModalKey)
    emit("onRoot", modalRef)
})
</script>

<style>
.modal-wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.15);
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding: 24px;
}
.modal-content {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    animation: falldown 250ms;
}
.closing .modal-content {
    animation: flyup 200ms;
}
</style>
