<template>
    <Layout>
        <div class="title-div">
            <BackButton></BackButton>
            <span></span>
        </div>
        <div class="news-div">
            <div>
                <div class="titl">Cover Image</div>
                <div class="landscape">
                    <div
                        class="upload-div"
                        id="img"
                        @click="handleFileInputTrigger"
                    >
                        <div class="spacer"></div>
                        <div class="desc default">
                            <div>Upload Image</div>
                            <div>960px * 540px</div>
                        </div>
                        <div class="desc h">
                            <div>Drop to Upload</div>
                        </div>
                    </div>
                    <div v-if="previews.img" class="preview-div">
                        <img class="preview-image" v-bind:src="previews.img" />
                        <button
                            class="link-button"
                            @click="handleDeletePreview('img')"
                        >
                            Delete
                        </button>
                    </div>
                    <input
                        type="file"
                        name="landscape_banner"
                        id="input-img"
                        multiple="0"
                        accept="image/png,image/jpeg"
                        @change="handleFileInput"
                        hidden
                    />
                </div>
            </div>
            <div class="v-line"></div>
            <div>
                <div class="news-content-div">
                    <label> Language </label>
                    <select
                        v-model="data.language"
                        @change="handleSelectLanguage"
                    >
                        <option value="en">English</option>
                        <option value="ms">Malay</option>
                        <option value="cn">Chinese</option>
                    </select>
                    <template
                        v-if="
                            data.language !== 'en' &&
                            permalinkSelection.length > 0
                        "
                    >
                        <label>Link to</label>
                        <select v-model="data.permalink">
                            <option
                                v-for="(pl, i) in permalinkSelection"
                                :key="i"
                                :value="pl"
                            >
                                {{ pl }}
                            </option>
                        </select>
                    </template>
                    <!-- <div class="version-input-container">
                    <div v-if="data.language !== 'en'">
                        <label>
                            English version
                        </label>
                        <select v-model="data.englishVersion">
                            <option value="">-</option>
                            <option value="ms">Malay</option>
                            <option value="cn">Chinese</option>
                        </select>
                    </div>
                    <div v-if="data.language !== 'ms'">
                        <label>
                            Malay version
                        </label>
                        <select v-model="data.malayVersion">
                            <option value="">-</option>
                            <option value="ms">Malay</option>
                            <option value="cn">Chinese</option>
                        </select>
                    </div>
                    <div v-if="data.language !== 'cn'">
                        <label>
                            Chinese version
                        </label>
                        <select v-model="data.chineseVersion">
                            <option value="">-</option>
                            <option value="ms">Malay</option>
                            <option value="cn">Chinese</option>
                        </select>
                    </div>
                </div> -->
                    <!-- <label>
                    Date
                </label>
                <input type="date" v-model="data.date" /> -->
                    <label> News Title </label>
                    <input
                        type="text"
                        class="input-full"
                        v-model="data.title"
                    />
                    <label> Summary </label>
                    <textarea rows="4" v-model="data.summary"></textarea>
                    <div class="editor-div">
                        <CKEditor.component
                            :editor="editor"
                            :config="editorConfig"
                            v-model="data.article"
                            @input="onEditorInput"
                        ></CKEditor.component>
                    </div>
                </div>
            </div>
        </div>
        <div class="cta-div">
            <button class="cta-button" @click="handleSave">Create New</button>
        </div>
    </Layout>
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, ref } from "vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-vue"
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import { useRouter } from "vue-router"
import Layout from "../layout/Layout.vue"
import BackButton from "../shared/UI/BackButton.vue"
import { toast } from "../../composable/useToast"
import { getTodayDate } from "../../composable/useHelper"
import { getAPI, postAPI } from "../../composable/useFetch"
import { handleSideNav } from "../../composable/useSidenav"
import { setLoader } from "../../composable/useLayoutLoader"

const router = useRouter()

const data = reactive({
    language: "en",
    title: "",
    date: getTodayDate(),
    summary: "",
    article: "",
    permalink: "",
    img: null,
})

const permalinkSelection = ref([])

const previews = ref({
    img: null,
})

const editorInput = ref()
const editor = ref(ClassicEditor)
const editorConfig = ref({
    ckfinder: {
        uploadUrl: `/api/news/upload?token=${localStorage.getItem(
            "access_token"
        )}`,
    },
    height: 200,
})

const onEditorInput = (e) => {
    editorInput.value = e
}

const handleFileInputTrigger = (e) => {
    const id = e.currentTarget.id
    if (!id) {
        return
    }
    document.querySelector(`#input-${id}`).click()
}

const handleFileInput = (e) => {
    const fileToProcess = e.target.files[0]
    previewFile(fileToProcess, e.currentTarget.id.split("-")[1])
    uploadFile(fileToProcess, e.currentTarget.id.split("-")[1])
}

const handleDeletePreview = (id) => {
    previews.value[id] = null
}

function highlight(e) {
    e.preventDefault()
    e.stopPropagation()
    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (!a.classList.contains("highlight")) {
            a.addEventListener("drop", handleDrop, { once: true })
        }
        a.classList.add("highlight")
    })
}

function unhighlight(e) {
    e.preventDefault()
    e.stopPropagation()

    const dropAreas = document.querySelectorAll(".upload-div")
    dropAreas.forEach((a) => {
        if (a.classList.contains("highlight")) {
            a.removeEventListener("drop", handleDrop)
        }
        a.classList.remove("highlight")
    })
}

function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    unhighlight(e)
    let dt = e.dataTransfer
    let files = dt.files
    const fileToUpload = files[0]
    previewFile(fileToUpload, e.currentTarget.id)
    uploadFile(fileToUpload)
}

function previewFile(file, id) {
    if (!file) {
        return
    }

    const acceptedFileType = ["image/png", "image/jpeg", "image/jpg"]
    if (!acceptedFileType.includes(file.type)) {
        // TOAST
        toast("Invalid file type", "error")
        return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
        previews.value[id] = reader.result
    }
}

async function uploadFile(file) {
    const acceptedFileType = ["image/png", "image/jpeg", "image/jpg"]
    if (!acceptedFileType.includes(file.type)) {
        toast("Invalid file type", "error")
        return
    }
    data.img = file
}

const handleSelectLanguage = async (e) => {
    if (e.target.value === "en") {
        permalinkSelection.value = []
        return
    }
    const permalinks = await getAPI(
        `/api/news/permalinks?language=${e.target.value}`
    )
    permalinkSelection.value = permalinks

    data.permalink = permalinkSelection.value[0]
}

const handleSave = async (e) => {
    e.preventDefault()

    if (data.title.length < 20) {
        toast("Title should be more than 20 characters", "error")
        return
    }
    if (!data.img) {
        toast("Please upload a cover image", "error")
        return
    }

    const fd = new FormData()
    for (let i in data) {
        if (data[i]) {
            fd.append(i, data[i])
        } else {
            if (i === "permalink" && data.language === "en") {
                continue
            }
            toast(`Please fill in ${i}`, "error")
            return
        }
    }

    const saveResponse = await postAPI("/api/news", fd)
    if (saveResponse.id) {
        toast("News has been created")
        router.replace(`/news/${saveResponse.id}`)
    }
}

onMounted(() => {
    setLoader(false)

    const body = document.querySelector("body")

    body.addEventListener("dragenter", highlight, false)
    body.addEventListener("dragover", highlight, false)
    body.addEventListener("dragleave", unhighlight, false)
    body.addEventListener("drop", unhighlight, false)

    handleSideNav(false)
})

onBeforeUnmount(() => {
    const body = document.querySelector("body")

    body.removeEventListener("dragenter", highlight, false)
    body.removeEventListener("dragover", highlight, false)
    body.removeEventListener("dragleave", unhighlight, false)
    body.removeEventListener("drop", unhighlight, false)
})
</script>

<style scoped>
.news-content-div select,
.news-content-div input,
.news-content-div textarea {
    margin-bottom: var(--form-input-gap);
}
label {
    display: block;
    margin-bottom: 8px;
}
.news-div {
    margin-top: var(--content-gap);
    display: grid;
    grid-template-columns: var(--news-grid-template-column);
    gap: var(--content-gap);
    text-align: left;
}
.news-div > * {
    min-width: 0;
}
.titl {
    color: var(--color-kt-blue);
    font-size: 30px;
    margin-bottom: 12px;
}
.landscape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.portrait {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--content-gap);
}
.upload-div {
    position: relative;
    border-radius: 4px;
    border: 2px dashed #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.landscape .upload-div {
    width: 100%;
}
.landscape .upload-div > .spacer {
    width: 100%;
    padding-bottom: calc(540 / 960 * 100%);
}

.landscape .preview-div {
    width: 100%;
}
.portrait .preview-div {
    width: calc(50% - var(--content-gap));
}

.preview-div > .card {
    overflow: hidden;
    background: #eee;
    border: 1px solid #979797;
    border-radius: 4px;
}
.landscape .preview-div .card {
    width: 33%;
    padding-bottom: calc(33% * 540 / 960);
}
.banner-div a {
    display: block;
    margin-top: 12px;
}
.preview-image {
    overflow: hidden;
    border: 1px solid #979797;
    border-radius: 4px;
    object-fit: cover;
}
.landscape .preview-image {
    width: 35%;
    aspect-ratio: 960 / 540;
}
</style>
