<template>
    <div class="modal-container">
        <div class="modal-box">
            <div class="modal-confirmation-message">
                {{ props.message || "Are you sure?" }}
            </div>
            <div class="modal-action">
                <button class="button yellow-outline" @click="handleClose">
                    Cancel
                </button>
                <button
                    class="button yellow"
                    @click="handleConfirm"
                    :disabled="isSubmitting"
                >
                    Confirm
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
const props = defineProps({
    message: String,
    isSubmitting: Boolean,
    id: Number,
})

const emit = defineEmits(["onSubmit", "onClose"])

const handleConfirm = () => {
    emit("onSubmit", props.id, handleClose)
}

const handleClose = () => {
    emit("onClose")
}
</script>

<style scoped>
.modal-box {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.modal-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    column-gap: 24px;
    row-gap: 18px;
}
</style>
