import { createApp } from "vue"
import CKEditor from "@ckeditor/ckeditor5-vue"
import App from "./App.vue"
import router from "./router"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import {
    faPen,
    faChevronRight,
    faArrowRightFromBracket,
    faTrashCan,
    faImage,
    faCircleQuestion,
    faSpinner,
    faBars,
} from "@fortawesome/free-solid-svg-icons"

library.add(
    faChevronRight,
    faArrowRightFromBracket,
    faPen,
    faTrashCan,
    faImage,
    faCircleQuestion,
    faSpinner,
    faBars
)

const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(CKEditor)

app.use(router).mount("#app")
